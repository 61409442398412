import klimaImg from './images/klima_blue.jpg'
import klimaImg2 from './images/klima_2.jpg'

import ideinImg from './images/idein_green.jpg'
import ideinImg2 from './images/idein_2.jpg'

import orgelImg from './images/orgel_4.jpg'
import orgelImg2 from './images/orgel_1.jpg'

import jumellesImg1 from './images/jumelles_3.jpg'
import jumellesImg2 from './images/jumelles_1.jpg'

import kielImg from './images/kiel_peach.jpg'
import sorelleImg from './images/sorelle_red.jpg'
import dateinImg from './images/datein_screen.png'
import rumoriImg from './images/Rumori_1.jpg'
import terpsichoreImg from './images/terpsichore_1.jpg'

import usqImg from './images/usq_new_4.jpg'
import usqImg2 from './images/usq_new_1.jpg'

export const PROJECTS = [
    {
        id: 0,
        name: "USQ",
        image: usqImg,
        image2: usqImg2,
        shortDesc: "Multiprogram Console",
        link: "",
        linkTitle: "",
        longDesc: "The USQ prototype consists of a control board and a PCB edge program board. This allows the user to switch out new programs as they arrive or to build their own. The first program is an 'unclocked' sequencer, a bit of a misnomer. Its function is based on a series of gate delays, with each of the 8 to 16 user-defined steps set in milliseconds. With start, pause and reset triggers, the user is able to achieve wonky rhythms, bursts or even drones at very short intervals. MIDI communication is in the works. The firmware is a Zephyr RTOS application for an STM32F446re controller. The console shown here is made of bent stainless steel with brass spacers and stainless steel mesh, designed by myself."
    },
    {
        id: 1,
        name: "Orgel",
        image: orgelImg,
        image2: orgelImg2,
        shortDesc: "Desktop Analog Synthesizer",
        link: "",
        linkTitle: "",
        longDesc: "The Orgel prototype shown here is my first experiment with analog sound generation. Four analog saw-tooth core voltage controlled oscillators are fed to a series of VCAs that are digitally controlled by low-pass filtered PWM signals. Borrowing the light sensor design from my optic gate generator Eurorack module, Idein, the information from the all light sensor via i2C is the main source of modulation. The depth of modulation is set by the series of slide potentiometers at the top, while the four rotary potentiometers set the envelope for each channel. The second eye exposes a digital compass that sets the parameters for a phase-shift effect. These two sources of modulation are meant to encourage unconventional interaction with the instrument, e.g. using a hand like one would with a theremin and facing different directions. Modulation primarily affects the wave-shapping circuitry of each voice. Although still in the experimental phase, the main 'keys' of the instrument will remain almost invisible below the surface of the aluminum front panel. I'll say more about this when I have it working. Product design of this prototype is original, with 3mm milled aluminum front and bottom panel and a routed 3/4-inch American walnut body."
    },
    {
        id: 2,
        name: "Jumelles",
        image: jumellesImg1,
        image2: jumellesImg2,
        shortDesc: "Twin-T Percussive Synthesizer",
        link: "",
        linkTitle: "",
        longDesc: "The Jumelles prototype is a self-contained adaption of the Sorelle eurorack module I designed a while back. The idea was to build a stand alone toy for play. This design is all analog and consists of two identical triangle core voltage controlled oscillators. These oscillators run through an attenu-verter as well as an off-set circuit before feeding into a series of logic ICs to output pseudo-random patterns. These binary patters trigger three resonant Twin-T filters tuned to different frequencies, outputting vintage-sounding percussive sounds. The initial prototype shown here includes a number of openings in the front panel for some control over the resonant Q factor as well as the frequency of each drum with the use of light-dependent resistors (LDRs). Two photodiodes act as switches that turn on and off their respsective oscillator. I'm currently rethinking these light controls as they leave a little too much room for unwieldiness. The body of the instrument is an original and, like the USQ prototype, is made of bent stainless steel with an ABS base. As a toy, this will probably end up in a much lighter body."
    },
    {
        id: 3,
        name: "Klima",
        image: klimaImg,
        image2: klimaImg2,
        shortDesc: "Atmospheric Logic",
        link: "https://youtu.be/aZcasxYljBo",
        linkTitle: "Watch Demo Video on YouTube",
        longDesc: "Klima welcomes in the surrounding atmosphere as a fundamental building block for modular patches. Inspired by traditional logic modules and based on the Bosch BME280 sensor, KLIMA derives random gates by comparing analog signals to the ambient temperature, humidity, atmospheric pressure and relative altitude. Klima makes a gesture towards moving the environment, its weather and atmosphere away from its position as backdrop or object of representation, to an active agent of music-making. With the Kiel module, Klima can produce random stepped control voltages to use as melodies and more."

    },
    {
        id: 4,
        name: "Idein",
        image: ideinImg,
        image2: ideinImg2,
        shortDesc: "Optic Gate Generator",
        link: "",
        linkTitle: "",
        longDesc: "Idein is an autonomous module that requires no user-input aside from light. An All-Light Sensor monitors levels of the Red, Green, Blue (RGB) and Infrared (IR) spectrums and produces quasi-random gates and triggers based on the color light spectrum of the surrounding environment."
    },
    {
        id: 5,
        name: "Kiel",
        image: kielImg,
        shortDesc: "Klima-Idein Expander Link",
        link: "",
        linkTitle: "",
        longDesc: "The Klima-Idein Expander Link is a 2hp expander module for use with the atmospheric logic module Klima, or the optic gate trigger Idein. It is based on a Parallel In Parallel Out (PIPO) Shift Register circuit, and receives the gate outputs from either of the aforementioned modules (not both) through a connector on the back panel. These gates are converted into two related channels of random stepped voltages. Controls on the front panel include global voltage range of both channels, a loop knob that locks to 8 or 16 steps, and a bit shifter that determines which bit is flipped by which incoming gate. Two clock inputs for each channel allows for asynchronous stepping."
    },
    {
        id: 6,
        name: "Sorelle",
        image: sorelleImg,
        shortDesc: "Analog Multi-Comparator",
        link: "",
        linkTitle: "",
        longDesc: "Sorelle produces a variety of modulation sources, including ring modulation, a bi-polar envelope summing circuit, two voltage comparators, a phase comparator and a weighted binary sequencer and gate output with CV range control. Each input signal is routed through an attenuverter and DC Offset circuit, allowing for manual modulation of the inputs before they are compared. I've coded a working virtual iteration of Sorelle for the VCV rack platform that I plan to upload for free use in the coming months."
    },
    {
        id: 7,
        name: "Datein",
        image: dateinImg,
        shortDesc: "JavaScript Synth App",
        link: "https://www.marcrickenbach.com/datein",
        linkTitle: "Play with it here",
        longDesc: "DATEIN is a JavaScript-based web app that allows for playful data-mining of user-supplied files that results in accidental rhythms and sequences. Once a file is loaded, it is translated into a binary string from which patterns can be selected. Each channel produces a different percussive sound. The sound design was developed using the Tone.js library."
    },
    {
        id: 8,
        name: "Rumori",
        image: rumoriImg,
        shortDesc: "Eavesdropping Voltage Source",
        link: "",
        linkTitle: "",
        longDesc: "Currently in prototype with most parts working as expected. Rumori is a multi-color noise module and fixed filter bank, which serves as a random voltage source for four independent (though related) digital sample and hold circuits. Two more inputs -- a radio receiver and condensor microphone -- make this an 'eavesdropping' module, bringing in the surrounding, overheard, noise into the modular system."
    },
    {
        id: 9,
        name: "Terpischore",
        image: terpsichoreImg,
        shortDesc: "Signal Animator",
        link: "",
        linkTitle: "",
        longDesc: "My initial project, Terpsichore is a digitally-controlled vactrol-based VCA meant to facillitate multi-channel panning. Each channel takes a voltage input (CV or Audio), and outputs two channels. The way the outputs behave is determined both by the incoming control voltage as well as the user-selected mode. Features include panning, sequenced signal switching, delay lines and morphings. I have a working prototype but put the project on hold while waiting out the chip shortage. MCU is an STM32 chip."
    }

]